
*,
*::before,
*::after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

}

body {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  font-size: 14px;
  font-family: 'Montserrat', 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF;
  }

#root {
  position: relative;
  height: auto;
  min-height: 100vh;
  max-width: 100vw;
  border-left-style: hidden;
  background-color: #F1F1F1;
  transition: all 0.1s linear 0s;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
#app-container {
  min-height: 100vh;
  max-width: 100vw;
}

img {
  max-width: 100%;
  display: block;
}

amplify-s3-image {
  /* max-width: 150px;
  max-height: 150px; */
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  --width: inherit;
  --height: 100%;
}

.notification-parent,
.notification-title,
.notification-message,
.notification-item {
  font-family: 'Montserrat', 'Open Sans';
}

body::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: lightgray;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: gray;    /* color of the scroll thumb */
  border-radius: 10px;       /* roundness of the scroll thumb */
  border: 1px solid lightgray;  /* creates padding around scroll thumb */
}

* {
  scrollbar-width: thin;
}
